import request from '@/config/axios'
import { formatTime } from '@/utils'

export const getListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/classes', params })

  if (params.page) {
    res.data = res.data
    return res
  } else {
    return res
  }
}

export const getAttendRecordApi = async (params: any): Promise<IResponse> => {
  let res = await request.get({ url: '/classes/attendRecord', params })

  const changeSchoolYear = (x) => {
    x.school_year = getStudentClassName(x.name, x.subject, x.school_year)
    x.created_at = formatTime(x.created_at, 'yyyy-MM-dd HH:mm')
    return x
  }

  if (params.page) {
    res = res?.map(changeSchoolYear)
    return res
  } else {
    return res.map(changeSchoolYear)
  }
}

export const saveApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/classes/${data.id}`, data })
  } else {
    return await request.post({ url: '/classes', data })
  }
}

export const getApi = async (id: string): Promise<TableData> => {
  const res = await request.get({ url: `/classes/${id}` })
  return res
}

export const delApi = async (ids: string[] | number[]): Promise<IResponse> => {
  return await request.delete({ url: `/classes`, params: { ids } })
}

export function getSubjectList() {
  return [
    { label: '國文', value: '國文' },
    { label: '英文', value: '英文' },
    { label: '數學', value: '數學' },
    { label: '自然', value: '自然' },
    { label: '社會', value: '社會' },
    { label: '安親', value: '安親' },
    { label: 'K書房', value: 'K書房' }
  ]
}
export function getNameList() {
  return [
    'A1',
    'A2',
    'A3',
    '私校',
    '職校',
    '英文混齡',
    '資優',
    '生物',
    '高一自然',
    '高一自然資優',
    '物理',
    '化學',
    '學測複習',
    '高年級',
    '中年級(三)',
    '中年級(四)',
    'K書房',
    '小學數學資優',
    '小學數學進度',
    '小學國文高年級',
    '小學國文中年級',
    '課輔',
    '社會',
    '系統測試'
  ].map((x) => ({ label: x, value: x }))
}

export function getCurrentStudentClassNameList(name, subject) {
  const current = getCurrentDate()

  const res = []

  let loopTimes = 12
  if (name === '英文混齡' && subject === '英文') {
    loopTimes = 6
  } else {
    res.push({
      label: '混齡',
      value: '混齡'
    })

    res.push({
      label: '短期班',
      value: '短期班'
    })
  }

  for (let i = -1; i < loopTimes - 1; i++) {
    res.push({
      label: getStudentClassName(name, subject, current.year - i),
      value: current.year - i
    })
  }
  return res
}

export function getStudentClassName(name, subject, school_year) {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const changeOverDate = new Date(currentYear, 6, 1) // 7月1日（月份從0開始計數）

  let gradeOffset = 0
  if (currentDate >= changeOverDate) {
    gradeOffset = 1
  }

  const ageDifference = currentYear - school_year + gradeOffset

  let str = ''
  if (!school_year) {
    return ''
  } else if (name === '英文混齡' && subject === '英文') {
    if (ageDifference === 0) {
      str = 'starter'
    } else if (ageDifference === 1) {
      str = 'starter A+'
    } else if (ageDifference === 2) {
      str = '基礎'
    } else if (ageDifference === 3) {
      str = '初級'
    } else if (ageDifference === 4) {
      str = '進階'
    } else if (ageDifference === 5) {
      str = '精修'
    } else if (ageDifference > 5) {
      str = '已畢業'
    }
  } else if (school_year === '短期班') {
    str = name
  } else if (school_year !== '混齡') {
    if (ageDifference === 0) {
      str = '小一'
    } else if (ageDifference === 1) {
      str = '小二'
    } else if (ageDifference === 2) {
      str = '小三'
    } else if (ageDifference === 3) {
      str = '小四'
    } else if (ageDifference === 4) {
      str = '小五'
    } else if (ageDifference === 5) {
      str = '小六'
    } else if (ageDifference === 6) {
      str = '國一'
    } else if (ageDifference === 7) {
      str = '國二'
    } else if (ageDifference === 8) {
      str = '國三'
    } else if (ageDifference === 9) {
      str = '高一'
    } else if (ageDifference === 10) {
      str = '高二'
    } else if (ageDifference === 11) {
      str = '高三'
    } else if (ageDifference > 11) {
      str = '已畢業'
    }
  }
  return `${str} (${school_year})`
}

function getCurrentDate() {
  const dateObj = new Date()
  const month = dateObj.getUTCMonth() + 1 //months from 1-12
  const day = dateObj.getUTCDate()
  const year = dateObj.getUTCFullYear()
  return { year, month, day }
}

export const getTeacherClassList = async (params?: any): Promise<TableData[]> => {
  const res = await request.get({ url: '/classes', params })
  const result = res.map((x) => {
    const label = `${x.subject} ${getStudentClassName(x.name, x.subject, x.school_year)} ${x.name}`
    const value = x.id

    return { label, value }
  })

  return result
}

export default {
  getListApi,
  getAttendRecordApi,
  saveApi,
  getApi,
  delApi,
  getStudentClassName,
  getCurrentStudentClassNameList,
  getSubjectList,
  getNameList,
  getTeacherClassList
}
