import request from '@/config/axios'
import { formatTime } from '@/utils'

export const getAssistantTeachersApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/teachers/assistants' })

  if (params.page) {
    res.data = res.data
    return res
  } else {
    return res
  }
}

export const saveAssistantTeachersApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/teachers/assistants/${data.id}`, data })
  } else {
    return await request.post({ url: '/teachers/assistants', data })
  }
}

export const delAssistantTeachersApi = async (ids: string[] | number[]): Promise<IResponse> => {
  return await request.delete({ url: `/teachers/assistants`, params: { ids } })
}


export default {
  getAssistantTeachersApi,
  saveAssistantTeachersApi,
  delAssistantTeachersApi
}