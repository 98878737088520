import login from './login'
import tutorialRollCall from './tutorialRollCall'
import classRollCall from './classRollCall'
import tutorialAttend from './tutorialAttend'
import studentStatus from './studentStatus'
import student from './student'
import theClass from './class'
import studentAttend from './studentAttend'
import studentTest from './studentTest'
import studentTestGrade from './studentTestGrade'
import tutorialPrior from './tutorialPrior'
import notification from './notification'
import manager from './manager'
import report from './report'
import payment from './payment'
import classRollCallTime from './classRollCallTime'
import teacher from './teacher'
export default {
  login,
  tutorialRollCall,
  classRollCall,
  tutorialAttend, 
  studentStatus, 
  student, 
  class: theClass,
  studentAttend,
  studentTest,
  studentTestGrade,
  tutorialPrior,
  notification,
  manager,
  report,
  payment,
  classRollCallTime,
  teacher
}
