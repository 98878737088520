import request from '@/config/axios'

export const unAttendListApi = async (params) => {
  const { data, meta } = await request.get({
    url: `/studentAttends/unAttendList`,
    params
  })
  return { data, meta }
}


export const listApi = ({ class_id, class_roll_call_id }) => {
  return async (params: any): Promise<IResponse> => {
    const res = await request.get({
      url: `/studentAttends/list`,
      params: { ...params, class_id, class_roll_call_id }
    })
    return res
  }
}

export const saveApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/studentAttends/${data.id}`, data })
  } else {
    return await request.post({ url: '/studentAttends', data })
  }
}
export const rollCallAllApi = async ({ class_roll_call_id, teacher_id, is_attend }): Promise<IResponse> => {
  return await request.post({ url: '/studentAttends/rollCallAll', data: { class_roll_call_id, teacher_id, is_attend } })
}

export const assistantsUnAttendListApi = async (params) => {
  const { data, meta } = await request.get({
    url: `/studentAttends/assistantsUnAttendList`,
    params
  })
  return { data, meta }
}



export default {
  unAttendListApi,
  saveApi,
  listApi,
  rollCallAllApi,
  assistantsUnAttendListApi
}
