import request from '@/config/axios'
import { formatTime } from '@/utils'

export const getListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/classRollCalls', params })

  return {
    data: res.data.map((x) => {
      x.time = formatTime(x.time, 'yyyy-MM-dd HH:mm')
      return x
    }),
    meta: res.meta
  }
}

export const getAssistantListApi = async (params: any): Promise<IResponse> => {
  const res = await request.get({ url: '/classRollCalls/assistant', params })

  return {
    data: res.data.map((x) => {
      x.time = formatTime(x.time, 'yyyy-MM-dd HH:mm')
      return x
    }),
    meta: res.meta
  }
}

export const saveApi = async (data: Partial<TableData>): Promise<IResponse> => {
  if (data.id) {
    return await request.put({ url: `/classRollCalls/${data.id}`, data })
  } else {
    return await request.post({ url: '/classRollCalls', data })
  }
}

export function getClassroomList() {
  return [
    { label: '數學大', value: '數學大' },
    { label: '數學小', value: '數學小' },
    { label: '新教室大', value: '新教室大' },
    { label: '新教室小', value: '新教室小' },
    { label: '新教室吧台', value: '新教室吧台' },
    { label: '高中大', value: '高中大' },
    { label: '高中外', value: '高中外' },
    { label: '高中小', value: '高中小' },
    { label: '舊K大', value: '舊K大' },
    { label: '舊K小', value: '舊K小' },
    { label: '新K大', value: '新K大' },
    { label: '新K小', value: '新K小' },
    { label: '英文小', value: '英文小' },
    { label: '英文中', value: '英文中' },
    { label: '英文大', value: '英文大' },
    { label: '理化小', value: '理化小' },
    { label: '理化中', value: '理化中' },
    { label: '理化大', value: '理化大' }
  ]
}

export const delApi = async (ids: string[] | number[]): Promise<IResponse> => {
  return await request.delete({ url: `/classRollCalls`, params: { ids } })
}

export const updateClassStatusApi = async (id: number, status: 'init' | 'change' | 'stop' | 'end' ): Promise<IResponse> => {
  return await request.put({ url: `/classRollCalls/${id}`, data: { status }})
}

export default {
  getListApi,
  saveApi,
  delApi,
  updateClassStatusApi,
  getClassroomList,
  getAssistantListApi
}
